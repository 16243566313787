import { getAccessToken } from "AccessToken";
import { hotelxSocketUrls } from "client";
import React, { createContext, useState, useEffect } from 'react';
import io from 'socket.io-client';

export const SocketContext = createContext(null); // Use null as the initial value
export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const token = getAccessToken();

  useEffect(() => {
    const newSocket = io(hotelxSocketUrls,{transports:["polling"], upgrade: false});
    setSocket(newSocket);

    // Show the popup dialog when the user tries to leave the page
    const onBeforeUnload = () => {
      newSocket.emit('data', {
        Status: 'LOGOUT',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    // Reconnect when the token is available
    if (token) {
      newSocket.emit('data', {
        Status: 'LOGIN',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });
    }

    // Cleanup function
    return () => {
      newSocket.disconnect();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [token]); // Add token as a dependency

  const LOGOUT = () => {
    if (socket) {
      socket.emit('data', {
        Status: 'LOGOUT',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });

      socket.disconnect();
    }
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        LOGOUT
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
